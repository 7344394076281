/* eslint-disable react/prop-types */
/* globals window CustomEvent */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
exports.onInitialClientRender = () => {
    console.log("ReactDOM.render has executed");
    try{
        eval("window.react_ready()");
    }catch(e){
        console.log(e)
    }
    
};

exports.onRouteUpdate = ({
    location
}) => {
    console.log("onRouteUpdate: new pathname:", location.pathname);
    if (
        location.pathname === "/blog" ||
        location.pathname === "/guard" ||
        location.pathname === "/butler" ||
        location.pathname === "/"
    ) {
        setTimeout(function () {
            try{
                eval("window.react_ready()");
            }catch(e){
                console.log(e)
            }
        }, 500);
    }
};