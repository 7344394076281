// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/Users/Kylw/Documents/project/singou-website/singou-robot/src/templates/blog_post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-pages-404-js": () => import("/Users/Kylw/Documents/project/singou-website/singou-robot/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("/Users/Kylw/Documents/project/singou-website/singou-robot/src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-blog-js": () => import("/Users/Kylw/Documents/project/singou-website/singou-robot/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-butler-js": () => import("/Users/Kylw/Documents/project/singou-website/singou-robot/src/pages/butler.js" /* webpackChunkName: "component---src-pages-butler-js" */),
  "component---src-pages-contactus-js": () => import("/Users/Kylw/Documents/project/singou-website/singou-robot/src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-guard-js": () => import("/Users/Kylw/Documents/project/singou-website/singou-robot/src/pages/guard.js" /* webpackChunkName: "component---src-pages-guard-js" */),
  "component---src-pages-index-js": () => import("/Users/Kylw/Documents/project/singou-website/singou-robot/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("/Users/Kylw/Documents/project/singou-website/singou-robot/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-video-js": () => import("/Users/Kylw/Documents/project/singou-website/singou-robot/src/pages/video.js" /* webpackChunkName: "component---src-pages-video-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/Kylw/Documents/project/singou-website/singou-robot/.cache/data.json")

